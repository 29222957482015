import { Grid } from '@hexa-ui/components';
import {
  AddTag,
  BookOpen,
  CheckPostdated,
  List,
  Megaphone,
  Store,
  Tiles2,
  Truck,
  Warehouse,
  World
} from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import { useSidebar } from 'admin-portal-shared-services';
import React from 'react';
import { useIntl } from 'react-intl';
import ThemeSelection from '../../components/ThemeSelection';
import Router from '../../Router';

const AppBarConfigProvider = (): JSX.Element => {
  const AddTagIcon = () => <AddTag />;
  const WorldIcon = () => <World />;
  const WarehouseIcon = () => <Warehouse />;
  const TruckIcon = () => <Truck />;
  const Tiles2Icon = () => <Tiles2 />;
  const StoreIcon = () => <Store />;
  const MegaphoneIcon = () => <Megaphone />;
  const BookOpenIcon = () => <BookOpen />;
  const CheckPostdatedIcon = () => <CheckPostdated />;
  const ListIcon = () => <List />;

  const intl = useIntl();

  useSidebar({
    items: [
      {
        id: '3',
        title: intl.formatMessage({ id: 'sidebar.overview' }),
        icon: AddTagIcon,
        path: '/overview'
      },
      {
        id: '4',
        title: intl.formatMessage({ id: 'sidebar.quick_start' }),
        icon: WorldIcon,
        path: '/overview/quick-start'
      },
      {
        id: '5',
        title: intl.formatMessage({ id: 'sidebar.mfe_components' }),
        icon: WarehouseIcon,
        path: '/overview/mfes-components'
      },
      {
        id: '6',
        title: intl.formatMessage({ id: 'sidebar.tracking_monitoring' }),
        icon: TruckIcon,
        path: '/overview/tracking-monitoring'
      },
      {
        id: '7',
        title: intl.formatMessage({ id: 'sidebar.error_handling' }),
        icon: Tiles2Icon,
        path: '/overview/error-handling'
      },
      {
        id: '8',
        title: intl.formatMessage({ id: 'sidebar.omni_wrapper' }),
        icon: StoreIcon,
        path: '/overview/omni-wrapper'
      },
      {
        id: '9',
        title: intl.formatMessage({ id: 'sidebar.examples' }),
        icon: ListIcon,
        path: '/overview/examples'
      }
    ],
    utils: [
      {
        id: '8',
        title: intl.formatMessage({ id: 'sidebar.terms_of_service' }),
        icon: MegaphoneIcon,
        path: '/overview/terms-of-service'
      },
      {
        id: '9',
        title: intl.formatMessage({ id: 'sidebar.documentation_overview' }),
        icon: BookOpenIcon,
        path: 'https://ab-inbev.atlassian.net/wiki/spaces/SP/pages/1046741057/Overview',
        target: '_blank'
      },
      {
        id: '10',
        title: intl.formatMessage({ id: 'sidebar.privacy' }),
        icon: CheckPostdatedIcon,
        path: 'https://ab-inbev.atlassian.net/wiki/spaces/SP/pages/3138355587/useSidebar',
        target: '_blank'
      }
    ]
  });

  return (
    <StyledGrid type="fluid" sidebar>
      <Router />
      <Grid.Item xl={9} lg={9} md={9} />
      <Grid.Item xl={3} lg={3} md={3}>
        <section style={{ margin: '5rem 0', width: '100%' }}>
          <ThemeSelection />
        </section>
      </Grid.Item>
    </StyledGrid>
  );
};

export default AppBarConfigProvider;

const StyledGrid = styled(Grid.Container, {
  paddingTop: '$4',
  rowGap: '1rem',
  '@desktopSmall': {
    paddingTop: 0
  }
});
