import MessageMap from '../i18n.d';

const pt: MessageMap = {
  test: {
    translate: 'Tradução'
  },
  app: {
    close: 'Fechar',
    overview: 'Visão Geral',
    quick_start: 'Comece Aqui',
    main_mfes: 'Principais MFEs e Comp...',
    tracking_monitoring: 'Monitorando e Rastreando',
    error_handling: 'Tratamento de Erros',
    omni_wrapper: 'Omni Wrapper'
  },
  sidebar: {
    overview: 'Visão Geral',
    quick_start: 'Comece Aqui',
    mfe_components: 'MFES e Componentes',
    tracking_monitoring: 'Serviços Externos',
    error_handling: 'Tratando Erros',
    omni_wrapper: 'Omni Wrapper',
    examples: 'Exemplos',
    terms_of_service: 'Termos de Serviço',
    documentation_overview: 'Documentação',
    privacy: 'Privacidade'
  },
  welcome: {
    title: 'Bem-vindo ao BEES One',
    tab: 'Bem-vindo',
    what_is_bees_hub: 'O que é o BEES One?',
    what_is_bees_hub_description:
      '<p>O BEES One é um ecossistema de aplicações que permite que os times de desenvolvimento construam e implantem as suas aplicações individualmente, enquanto provemos um container central para unir todas as aplicações em uma experiência unificada.</p><p>Nosso objetivo é prover para todos os desenvolvedores um ambiente seguro e flexível para criar ótimas aplicações.</p>',
    what_is_the_bees_hub_framework: 'O que é o BEES One Framework?',
    what_is_the_bees_hub_framework_description:
      '<p>O BEES One Framework (BOF) é um conjunto de ferramentas, padrões e componentes prontos para uso que ajudam os desenvolvedores a focar nas regras de negócio enquanto constroem novas aplicações, seja dentro do BEES One ou em portais separados.</p><p>O próprio BEES One foi construído utilizando o BOF.</p>',
    know_our_dream_team: 'Conheça o nosso time!',
    applications_in_production: 'Mais de 40 aplicações em produção',
    how_to_get_access_to_bees_hub: 'Como ter acesso ao BEES One?',
    how_to_get_access_step_1:
      'Para acessar os ambientes, será necessário solicitar acesso no canal do slack #bees-hub-general, enviando as informações necessárias listadas abaixo.',
    click_here: 'Clique aqui para ver as informações necessárias',
    how_to_get_access_step_2:
      'Assim que o acesso for concedido, você receberá uma notificação em seu e-mail.',
    how_to_get_access_prod:
      'Para acesso à PROD, será necessário abrir um ticket para cada aplicação. Não se esqueça de enviar todas as informações necessárias.',
    required_information: 'Informações necessárias',
    open_ticket: 'Abrir ticket',
    us_bees_hub: 'US BEES One',
    us_bees_hub_description:
      '<p>Uma instância do BEES One está rodando de forma totalmente isolada para atender a todos os requisitos de separação de dados dos EUA.</p><p>Apenas profissionais que não são da ABI podem acessá-lo.</p><p>Por favor, entre em contato em caso de dúvidas.</p>',
    service_request: {
      user_email: 'E-mail do usuário',
      environment: 'Ambiente: Dev/SIT/UAT',
      country: 'País (país a ser cadastrado o usuário)',
      list_country: 'Lista de Países (se for um requisito da aplicação que será acessada)',
      authorization_group: 'Grupo de autorização'
    }
  },
  frontend_features: {
    tab: 'Recursos do Frontend',
    components: {
      title: 'Quais componentes posso usar?',
      description: 'Conheça nosso Storybook e veja todos os componentes de frontend disponíveis:',
      storybook: 'Link para o nosso Storybook',
      login_info: 'Dados para Login:',
      user: 'Usuário: ',
      password: 'Senha: '
    },
    language: {
      title: 'Quer mudar o idioma da aplicação?',
      description:
        'Você pode fazer isso indo na página de perfil de usuário em preferências de usuário:',
      change_language:
        'Ou você pode adicionar seu próprio componente. Vamos tentar com bandeiras dos países:'
    },
    auth: {
      title: 'Autenticação e Autorização',
      description: 'Nosso Framework está totalmente integrado às soluções de ',
      identity_solutions: 'Identity',
      authentication: 'Autenticação:',
      azure: 'Login pelo Azure B2C, Cadastro, Recuperação de senha',
      authorization: 'Autorização:',
      casbin:
        'Baseada no Framework Casbin, permite aos devs um controle fino das permissões dentro de cada aplicação'
    }
  },
  quick_start: {
    title: 'Comece Aqui',
    description: 'Comece a desenvolver seu MFE em poucos passos',
    mfe_generator: {
      title: 'MFE Generator',
      buttonTitle: 'Crie um novo MFE para integrar no BEES One',
      description:
        'Nós desenvolvemos um gerador de MicroFrontEnds, o qual lhe permitirá criar seu primeiro MFE em poucos passos. Todos os detalhes podem ser encontrados em nossa página no Confluence:',
      conclusion:
        'Ao final do tutorial você terá um novo MFE baseado no BEES One Framework e pronto para ser usado em sua aplicação.'
    },
    deployment: {
      title: 'Implantação',
      description:
        'Para ajudar nos primeiros passos do deploy de um novo MFE no BEES One indo mais além, implementando pipelines em conformidade com os padrões ABI, siga os links abaixo:',
      first_steps_button: 'Deploy - Primeiros Passos',
      golden_pipeline_button: 'Golden Pipeline V2'
    },
    code_examples: {
      title: 'Exemplos de código',
      description:
        'Gostaria de ter alguns exemplos de código? Além de todo o conteúdo em nosso confluence você poderá consultar o código desenvolvido para este MFE de demonstração:',
      repo_button: 'Repositório do Overview MFE'
    }
  },
  main_mfes_components: {
    title: 'Nossos MFEs e componentes',
    description: 'Conheça o que está disponível para todas as aplicações',
    top_menu: {
      title: 'Menu Superior',
      description:
        'O menu superior é um MFE desenvolvido pelo time do BEES One e pode ser customizado para cada aplicação.'
    },
    logo: {
      title: 'Logo',
      description:
        'Você pode adicionar uma logo customizada através de flavors. Para criar um nova flavor, você precisa entrar em contato com a equipe de design.',
      knowMore: 'Veja mais na página de configuração de tema'
    },
    sidebar_menu: {
      title: 'Sidebar',
      description: 'O desenvolvedor pode adicionar diferentes páginas e seções à aplicação',
      aboutHook:
        'A partir da versão 2.1.1 da biblioteca admin-portal-shared-services está disponível o hook useSidebar o qual o MFE pode usar para configurar os items de seu menu.',
      hookDocumentation: 'Página de documentação do hook useSidebar',
      step_1: 'Usando a sidebar, o portal irá mostrar uma barra lateral com os itens customizados.',
      step_2:
        'Clique no ícone no canto superior esquerdo da página e verifique o comportamento deste componente no celular, para telas maiores, você pode ver os itens da barra lateral à esquerda da tela.'
    },
    app_switcher: {
      title: 'App Switcher',
      description:
        'Este componente irá exibir todas as aplicações às quais o usuário logado tem acesso'
    },
    language_selector: {
      title: 'Seletor de Idioma',
      knowMorePreferredLanguage: 'Página de documentação do hook usePreferredLanguageV2',
      aboutDefaultLanguageSelection:
        'As linguagens do sistema podem ser alteradas na página de perfil de usuário em preferências de usuário. Para o MFE pegar a linguagem selecionada por essa página, provemos o hook usePreferredLanguageV2 que retorna a linguagem selecionada pelo usuário nesta tela.',
      aboutCustonLanguageSelection:
        'O desenvolvedor pode configurar o suporte à diferentes idiomas em seu MFE de uma forma customizada caso deseje como com componentes customizados abaixo por exemplo'
    }
  },
  error_handling: {
    title: 'Páginas de Erro e Avisos',
    subtitle:
      'Com páginas padronizadas podemos nos comunicar com o usuário de forma clara e consistente.',
    user_without_access: 'Usuário sem acesso a nenhuma aplicação',
    page_not_found: 'Página não encontrada',
    generic_error: 'Erro genérico'
  },
  tracking: {
    title: 'Serviços Externos',
    subtitle: 'Saiba e controle tudo que acontece com sua aplicação',
    description:
      'O BEES One Framework fornece ao desenvolvedor um conjunto de ferramentas pré-integradas para rastrear o comportamento do usuário e monitorar a saúde dos aplicativos.',
    new_relic:
      'As instâncias do BEES One são monitoradas pelo New Relic, usando a integração criada com nosso Framework. Ao definir o atributo personalizado mfeName nos logs e eventos, uma aplicação pode usar o mecanismo de filtragem e obter a telemetria de cada mfe',
    check_our_dashboards: 'Veja nossos Dashboards',
    more_details: 'Mais Detalhes',
    optimizely:
      '<p>Optimizely feature flags podem ser usadas para controlar o status de novas funcionalidades, as porcentagens de lançamento em releases controladas e direcionar funcionalidades por país.</p><p>Obtenha a chave do projeto e comece a usar nossa biblioteca pré-integrada</p>',
    segment:
      '<p>Rastreie o comportamento do usuário desde a primeira página, ao longo de toda a autenticação e dentro de cada aplicação.</p><p>Com o persistent AnonymousID, nós podemos rastrear todo o fluxo do usuário alinhado com os requisitos de SDI e com custos reduzidos</p>'
  },
  omniwrapper: {
    title: 'Omni Wrapper',
    subtitle: 'One wrapper to rule them all',
    description:
      '<p>O Omni Wrapper é uma aplicação de orquestração de microfrontend para gerencimento de rodas de aplicações, layout e dependências compartilhadas.</p><p>Ele habilita fast bootstrapping para novos MFes , com praticamente zero dependências entre times.</p>',
    motivation: {
      title: 'Motivação',
      description: `<p>Considere que o BEES One já tem mapeado aplicações com <bold>14 value streams (e aumentando)</bold>. Cada time terá opiniões diferentes sobre padrões de
      código e diretrizes. Manter um conjunto de ferramentas unificados, práticas e padrões é uma missão quase impossível.</p>
      <p>Nossa arquitetura atual tem limites não tem fronteiras claras entre aplicações, sendo difícil para desenvolvedores para fazerem mudanças com confiança e
      independência. Existem muitas lógicas de negócio entre aplicações que vive no wrapper atual. Desenvolvedores não podem efetivamente fazer testes em
      várias de suas mudanças por conta dessa dependência que não pode ser rodada localmente.</p>
      <p>O Omni Wrapper resolve esse problemas com uma arquitetura com foco em fronteiras claras. Nós usamos o <a>Layout Engine</a> da última versão do Single-spa
      para alcançar isso.</p>`
    },
    key_benefits: {
      title: 'Benefícios',
      benefits: {
        use_your_toolchain: {
          placeholder: 'Use sua caixa de ferramentas',
          tooltip:
            'O Omni Wrapper é construitdo sobre single-spa, dando autonomia em ferramentas e cadênca de deploy.'
        },
        create_new_applications: {
          placeholder: 'Criar novas aplicações sem dependências com backend',
          tooltip:
            'Omni Wrapper provê tudo que é necessário para deploy deaplicações fronteend. Desenvolva, faça deploy e atualize a página.'
        },
        test_changes: {
          placeholder: 'Teste em qualquer ambiente',
          tooltip:
            'Automações e QA podem facilmente testar mudanças antes que sejam promovidas para outros ambientes.'
        },
        develop_locally: {
          placeholder: 'Desenvolver localmente apontando para qualquer ambiente',
          tooltip:
            'Qualquer instância do Omni Wrapper pode ser usada para desenvolvimento local. Desenvolvedores não precisam mais de gambiarras no ambiente local e serviços mockados.'
        },
        prevent_monolithic: {
          placeholder: 'Prevê código monobloco',
          tooltip: `O Omni wrapper é um orquestrador do single-spa e é isso.
          Isso força a nova lógica e serviços ficarem fora do wrapper.`
        }
      },
      check_confluence_button: 'Veja nossa página no confluence para mais detalhes'
    }
  },
  terms_of_service: {
    title: 'Termos de Serviço',
    description:
      'Você pode redirecionar seu usuário para uma nova página, seja dentro ou fora do BEES One!'
  },
  get_help: {
    title: 'Obter Ajuda',
    description:
      'Precisa de alguma ajuda? Entre em contato conosco através do nosso canal no Slack <bold>#admin-portal-general</bold>.'
  },
  examples: {
    title: 'Exemplos',
    description:
      'Utilizamos o overview para implementar alguns componentes e features. Confira os exemplos disponíveis!'
  }
};

export default pt;
