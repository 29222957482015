import { createContextProvider } from '@hexa-ui/components';

export type Env = {
  env: {
    segmentKey: string;
  };
};

const initialValues = {
  env: {
    segmentKey: ''
  }
};

const [EnvProvider, useEnvProvider] = createContextProvider<Env>('EnvProvider', initialValues);

export { EnvProvider, useEnvProvider };
