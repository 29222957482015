/* eslint-disable no-nested-ternary */
import { Select } from '@hexa-ui/components';
import { useHubThemeState } from 'admin-portal-shared-services';
import React from 'react';
import { useSetThemeProvider } from '../../utils/setThemeProvider';

const Options = (): JSX.Element => (
  <>
    <Select.Option value="customer">Customer</Select.Option>
    <Select.Option value="partner">Partner</Select.Option>
    <Select.Option value="grow">Grow</Select.Option>
    <Select.Option value="care">Care</Select.Option>
    <Select.Option value="deliver">Deliver</Select.Option>
    <Select.Option value="force">Force</Select.Option>
    <Select.Option value="lift">Lift</Select.Option>
    <Select.Option value="tadaPrimary">TaDa Primary</Select.Option>
    <Select.Option value="tadaSecondary">TaDa Secondary</Select.Option>
  </>
);

const ThemeSelection = (): JSX.Element => {
  const initialValue = useHubThemeState();
  const provider = useSetThemeProvider();

  return (
    <Select.Root
      label="Choose your hub theme"
      onChange={(value) => {
        if (typeof provider?.setTheme === 'function') {
          provider.setTheme(value);
        }
      }}
      defaultValue={initialValue || undefined}
    >
      <Options />
    </Select.Root>
  );
};

export default ThemeSelection;
